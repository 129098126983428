<template>
  <div class="aboutUs">
    <div class="banner">
      <div class="title">允执其中 远举高飞</div>
    </div>
    <div class="message">
      <div class="description">
        <div class="description_left">
          <p>
            <span class="title" style="">山东中飞科技有限公司</span
            >成立于2008年，是一家拥有自主知识产权的国家级高新技术企业。始终专注于医学教育领域的技术创新与产品研发，在医学教育信息化管理、虚拟仿真及物联网实验室等方面一直坚持自主研发，研发成果充分与临床应用相融合。
          </p>
          <p>
            产品涵盖：护理、临床、影像、检验、中医药、口腔、眼视光及基础医学等专业；同时提供实验类、技能类、课程类、资源类、管理类的权威性体系化产品，为推动医学实验教学信息化建设提供了新视角。
          </p>
          <p>产品呈现形式：以PC、移动、VR、AR、MR、全息等多种载体呈现。</p>
        </div>
        <div class="description_right">
          <img src="@/assets/aboutUs/zf.png" alt="" />
        </div>
      </div>
      <div class="honor">
        <div class="title">获得荣誉</div>
      </div>
      <div class="development_history">
        <div class="title">发展历程</div>
        <img src="@/assets/aboutUs/development_history.png" alt="" />
      </div>
      <div class="culture">
        <div class="title">文化释义</div>
        <div class="culture_content">
          <div class="culture_left">
            <img src="@/assets/aboutUs/hill.png" alt="" />
          </div>
          <div class="culture_right">
            <p>
              中飞科技坚持以中庸之道进行自我修养，自我监督，自我教育，自我完善，只有这样才能飞的更远！
            </p>
            <p>
              儒家的中庸思想要求管理者处理事情要坚持原则、不偏不倚、避免偏激、确保人心稳定。对中飞而言，公司始终专注于医学教育领域的技术创新，坚持自主研发、持续改进的原则。为推动医学教学信息化建设提供了新视角。这正与不偏不倚的中庸思想相契合。另外，中飞一方面鼓励职工个性自由发展，另一方面协调职工个人与企业目标一致，激发职工的积极性、创造性，实现他们的理想和抱负。这样的企业，其内部不仅稳定团结，凝聚力强，而且不断推陈出新，外部坚定不移朝着宏伟奋斗目标阔步前行，远举高飞！
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.aboutUs {
  width: 100%;
  .banner {
    position: relative;
    width: 100%;
    height: 522px;
    background: url("../../assets/aboutUs/banner.png") no-repeat;
    margin-left: -1px;
    .title {
      position: absolute;
      top: 234px;
      right: 383px;
      width: 309px;
      height: 35px;
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #181818;
    }
  }
  .message {
    width: 1460px;
    margin: 0 auto;
    margin-top: 70px;
    .description {
      display: flex;
      // justify-content: space-between;
      .description_left {
        p {
          margin-bottom: 50px;
          line-height: 40px;
          color: #1d1d1d;
          font-size: 21px;
        }
        .title {
          color: #1d1d1d;
          font-size: 28px;
          padding-right: 30px;
        }
      }
      .description_right {
        margin-left: 80px;
      }
    }
    .honor {
      margin-top: 54px;
      width: 100%;
      height: 830px;
      background: url("../../assets/aboutUs/honor.png") no-repeat;
      background-size: 100% 100%;
      .title {
        width: 147px;
        margin: 0 auto;
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        color: #181818;
      }
    }
    .development_history {
      .title {
        width: 147px;
        margin: 0 auto;
        margin-bottom: 88px;
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #181818;
      }
      width: 100%;
    }
    .culture {
      margin-top: 87px;
      .title {
        margin: 0 auto;
        width: 147px;
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #181818;
      }
      .culture_content {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        margin-bottom: 75px;
        .culture_left {
          margin-right: 65px;
        }
        .culture_right {
          font-size: 21px;
          font-family: PingFang SC;
          color: #1d1d1d;
          line-height: 40px;
          p {
            &:nth-of-type(1) {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
}
</style>
